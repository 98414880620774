import { GetRequest, PostRequest } from '../services/httpRequest';

export const APISeniorCitizenInfo = () => {
    return GetRequest('senior-citizen');
};
export const APISeniorCitizenInfoExport = (query: any) => {
    return GetRequest(`excel/export/senior-citizen?${query}`, { responseType: 'blob' });
};
export const APISeniorCitizenInfoImport = (file: any) => {
    return PostRequest('excel/import/senior-citizen', file);
};
export const APISeniorCitizenInfoPost = (data: any) => {
    return PostRequest('senior-citizen', data);
};
export const APISeniorCitizenInfoPut = (data: any, userId: any) => {
    return PostRequest(`senior-citizen/${userId}`, data);
};

export const APISeniorCitizenInfoSearch = (data: any) => {
    return GetRequest(`senior-citizen?${data}`);
};

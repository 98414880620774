 /* eslint-disable */
const nepaliDates:any = {
    0: [2e3, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    1: [2001, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    2: [2002, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    3: [2003, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    4: [2004, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    5: [2005, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    6: [2006, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    7: [2007, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    8: [2008, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    9: [2009, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    10: [2010, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    11: [2011, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    12: [2012, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    13: [2013, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    14: [2014, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    15: [2015, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    16: [2016, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    17: [2017, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    18: [2018, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    19: [2019, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    20: [2020, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    21: [2021, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    22: [2022, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    23: [2023, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    24: [2024, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    25: [2025, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    26: [2026, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    27: [2027, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    28: [2028, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    29: [2029, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    30: [2030, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    31: [2031, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    32: [2032, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    33: [2033, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    34: [2034, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    35: [2035, 30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    36: [2036, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    37: [2037, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    38: [2038, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    39: [2039, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    40: [2040, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    41: [2041, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    42: [2042, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    43: [2043, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    44: [2044, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    45: [2045, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    46: [2046, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    47: [2047, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    48: [2048, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    49: [2049, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    50: [2050, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    51: [2051, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    52: [2052, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    53: [2053, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    54: [2054, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    55: [2055, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    56: [2056, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    57: [2057, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    58: [2058, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    59: [2059, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    60: [2060, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    61: [2061, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    62: [2062, 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
    63: [2063, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    64: [2064, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    65: [2065, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    66: [2066, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    67: [2067, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    68: [2068, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    69: [2069, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    70: [2070, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    71: [2071, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    72: [2072, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    73: [2073, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    74: [2074, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    75: [2075, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    76: [2076, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    77: [2077, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    78: [2078, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    79: [2079, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    80: [2080, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    81: [2081, 31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    82: [2082, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    83: [2083, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    84: [2084, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    85: [2085, 31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    86: [2086, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    87: [2087, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
    88: [2088, 30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    89: [2089, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    90: [2090, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30]
  };
  
  
  export const  nepaliToEnglish=(year:any, month:any, day:any)=> {
    let n = 0
      , dayCount = 3
      , l = 0
      , noLeapMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      , leapMonth = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (isValidDate(parseInt(year), parseInt(month), parseInt(day))) {
        for (let i = 0; i < year - 2e3; i++) {
            for (let a = 1; a <= 12; a++) {
                n += getData(2e3 + l)[a]
            }
            l++
        }
        let data;
        for (let a = 1; a < month; a++)
       data  = getData(2e3 + l),
            n += data[a];
        n += day;
        let i = 13
          , monthCount = 4
          , yearCount = 1943
          , d = null;
        for (; 0 != n; )
            d = isLeapYear(yearCount) ? leapMonth[monthCount] : noLeapMonth[monthCount],
            ++i > d && (i = 1,
            ++monthCount > 12 && (yearCount++,
              monthCount = 1)),
            ++dayCount > 7 && (dayCount = 1),
            n--;
        // let y = dayCount;
        const englishDate:any = {year:'',month:'',day:''};
  
        // return {yearCount,
      //  monthCount,
    //  i}
    console.log(String(i).length);
    

     return englishDate.year = String(yearCount),
        englishDate.month = String(monthCount).length==1?`0${monthCount}`:`${monthCount}`,
        englishDate.day = String(i).length==1?`0${i}`:`${i}`,
        // englishDate.day = days[y],
        // englishDate.nmonth = nmonth[monthCount],
        // englishDate.numDay = y,
        englishDate
       
  
    }
    return console.log("Invalid date"),
    {
        error: "Please enter valid date range"
    }
  }
  
  // const days = {
  //   1: "Sunday",
  //   2: "Monday",
  //   3: "Tuesday",
  //   4: "Wednesday",
  //   5: "Thursday",
  //   6: "Friday",
  //   7: "Saturday"
  // }
  // , nmonth = {
  //   1: "January",
  //   2: "February",
  //   3: "March",
  //   4: "April",
  //   5: "May",
  //   6: "June",
  //   7: "July",
  //   8: "August",
  //   9: "September",
  //   10: "October",
  //   11: "November",
  //   12: "December"
  // }
  function getData(year:any) {
    for (let i = 0; i <= Object.keys(nepaliDates).length; i++)
        if (nepaliDates[i][0] == year)
            return nepaliDates[i]
  }
  function isValidDate(year:any, month:any, day:any) {
    if (year < 2e3 || year > 2090)
        return !1;
    let fullDateMonth = getData(year);
    return !(month < 1 || month > 12) && !(day > fullDateMonth[month] || day < 1)
  }
  function isLeapYear(year:any) {
    return year % 100 == 0 ? year % 400 == 0 : year % 4 == 0
  }
  
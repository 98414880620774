import { GetRequest, PostRequest } from '../services/httpRequest';

export const APIDisabilityInfo = () => {
    return GetRequest('user-info');
};
export const APIDisabilityInfoExport = (query: any) => {
    return GetRequest(`excel/export/user-info?${query}`, { responseType: 'blob' });
};
export const APIDisabilityInfoImport = (file: any) => {
    return PostRequest('excel/import/user-info', file);
};
export const APIDisabilityInfoPost = (data: any) => {
    return PostRequest('user-info', data);
};
export const APIDisabilityInfoPut = (data: any, userId: any) => {
    return PostRequest(`user-info/${userId}`, data);
};

export const APIDisabilityPrintCounterPost = (userId: any) => {
    return GetRequest(`user-info/print-counter/${userId}`);
};

export const APIDisabilityInfoSearch = (data: any) => {
    return GetRequest(`user-info?${data}`);
};

export const APIDisabilityInfoPublic = (userId: any) => {
    return GetRequest(`public/user-info/${userId}`);
};

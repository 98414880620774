import {
    bloodGroupData,
    disability_nature,
    disability_natureEN,
    idCardTypesEN,
    disabilitySeverityEN,
    genderEN,
    disabilitySeverity,
    gender,
    idCardTypes,
} from '../../../../utils/constants/Constants';
import { useEffect, useState } from 'react';
import { APIDisabilityInfoPublic } from '../../../../api/disabilityInfo';
import { useLocation } from 'react-router';
import './Viewinfo.css';
// @ts-ignore
import governmentLogo from '../../../../assets/images/logo.png';
import { IMAGE_URL } from '../../../../config/baseURL';

export const DisableInfoView = () => {
    const [result, setResult] = useState<any>();
    const {state,pathname} = useLocation()
    const userId = state?.data?.id ?? pathname.slice(pathname.lastIndexOf("/ "),pathname.length)
    const municipalityID = state?.municipalityID
    const user_image = `${IMAGE_URL}/storage/municipality/${municipalityID??1}/userinfo/image/${result?.image}`

    const fetchDisabilityData = async () => {
        try {
            const res: any = await APIDisabilityInfoPublic(userId);

            if (res?.status == 1) {
                setResult(res.data);
            }
        } catch (e) {
            await Promise.reject(e);
        }
    };

    useEffect(() => {
        fetchDisabilityData();
    }, []);

    return (
        <div>
            <div className="contain ">
                <div className="header">
                    <img className="header-logo" width={'100px'} src={governmentLogo} alt="government logo" />
                    <div className="header-detail">
                        <div className="title text-small ">नेपाल सरकार</div>
                        <div className="title">कटारी नगरपालिका</div>
                        <div className="title">नगर कार्यपालिकाको कार्यालय</div>
                        <div className="title text-small">कटारी, उदयपुर</div>
                        <div className="title text-small">कोशी प्रदेश, नेपाल</div>
                    </div>
                </div>
                <div className="card-title">अपांगता परिचय-पत्र</div>
                <div className="card-main">
                    <div className="flex">
                        <div className="left-content">
                            <div className="p-t">
                                परिचय पत्रको नम्बर:<span className="bold">{result?.identity_no ?? ''}</span>
                            </div>
                            <div className="p-t">
                                नाम, थर: <span className="bold">{result?.full_nameNP ?? ''}</span>
                            </div>
                            <div className="p-t">
                                नागरिकता नम्बर:<span className="bold">{result?.citizenship_no || '__________'}</span>
                            </div>
                            <div className="p-t">
                                रक्त समूह:{' '}
                                <span className="bold">
                                    {bloodGroupData.find((v) => v.value == result?.blood_group)?.label || '______________'}
                                </span>
                            </div>
                            <div className="">
                                {' '}
                                जिल्ला: <span className="bold">उदयपुर</span>
                            </div>
                        </div>
                        <div className="left-content">
                            <div className="p-t">
                                परिचय पत्रको प्रकार:{' '}
                                <span className="bold">
                                    {idCardTypes.find((v) => v.value == result?.card_type_id)?.label ?? ''}
                                </span>
                            </div>
                            <div className="p-t">
                                जन्म मिति: <span className="bold">{result?.date_of_birthNP || '________________'}</span>
                            </div>
                            <div className="p-t">
                                लिङ्ग:{' '}
                                <span className="bold">
                                    {gender.find((v) => v.value == result?.gender)?.label || '____________'}
                                </span>
                            </div>
                            <div className="p-t">
                                <span className="address-info">
                                    ठेगाना: <span className="bold">{result?.addressNP}</span>
                                </span>
                            </div>
                            <div className="">
                                {' '}
                                स्थानीय तह: <span className="bold">कटारी नगरपालिका</span>
                            </div>
                        </div>
                    </div>
                    <div className="user-image">
                        <img src={user_image} alt="user-image" />
                    </div>
                </div>
                <div className="p-t">
                    अपांगताको किसिम:
                    <span className="p-t p-r">
                        <span className="bold p-l">
                            {disability_nature.find((v) => v.value == result?.disability_nature)?.label || '________________'}
                        </span>
                    </span>
                    <span className="p-t">
                        गम्भीरता{' '}
                        <span className="bold p-l">
                            {disabilitySeverity.find((v) => v.value == result?.disability_severity)?.label || '________________'}
                        </span>
                    </span>
                </div>
                <div className="flex">
                    <div className="left-content">
                        <div className="p-t">
                            संरक्षकको नाम,थर: <span className="bold">{result?.guardians_nameNP || '________________'}</span>
                        </div>
                        <div className="p-t">परिचयपत्र वाहकको दस्तखत: {'' || '________________'}</div>
                        <div className="p-t">
                            परिचयपत्र प्रमणित गर्ने:<span className="bold"> {result?.approved_byNP || '_________'}</span>
                        </div>
                    </div>
                    <div className="left-content">
                        <div className="p-t">
                            नाम, थर: <span className="bold">{result?.full_nameNP || '____________'}</span>
                        </div>
                        <div className="p-t">हस्ताक्षर: {'' || '__________'}</div>
                        <div className="p-t">
                            पद:<span className="bold"> {result?.positionNP || '_______'}</span>
                        </div>
                        <div className="p-t">मिति: {'_____________'}</div>
                    </div>
                </div>
                <div className="lose-case">
                    <span>यो परिचयपत्र कसैले पाएमा नजिकको प्रहरी कार्यालय वा स्थानीय निकायमा बुझाई दिनुहोला |</span>
                </div>
            </div>
            <div className="contain">
                <div className="header">
                    <img className="header-logo" width={'100px'} src={governmentLogo} alt="government logo" />
                    <div className="header-detail">
                        <div className="title text-small ">The Government of Nepal</div>
                        <div className="title">Katari Municipality</div>
                        <div className="title">Municipal Executive Office</div>
                        <div className="title text-small">Katari, udayapur</div>
                        <div className="title text-small">Koshi Province, Nepal</div>
                    </div>
                </div>
                <div className="card-title">Disability Identity Card</div>
                <div className="card-main">
                    <div className="flex">
                        <div className="left-content">
                            <div className="p-t">
                                ID Card No.:<span className="bold">{result?.identity_no ?? ''}</span>
                            </div>
                            <div className="p-t">
                                Full Name: <span className="bold capitalize">{result?.full_nameEN ?? ''}</span>
                            </div>
                            <div className="p-t">
                                Citizenship Number:<span className="bold">{result?.citizenship_no || '_________'}</span>
                            </div>
                            <div className="p-t">
                                Blood Group:{' '}
                                <span className="bold">
                                    {bloodGroupData.find((v) => v.value == result?.blood_group)?.label || '______________'}
                                </span>
                            </div>
                            <div className="">
                                {' '}
                                Distric: <span className="bold">Udayapur</span>
                            </div>
                        </div>
                        <div className="left-content">
                            <div className="p-t">
                                ID Card Type:{' '}
                                <span className="bold">
                                    {idCardTypesEN.find((v) => v.value == result?.card_type_id)?.label ?? ''}
                                </span>
                            </div>
                            <div className="p-t">
                                Date of Birth: <span className="bold">{result?.date_of_birthEN || '________________'}</span>
                            </div>
                            <div className="p-t">
                                Sex:{' '}
                                <span className="bold">
                                    {genderEN.find((v) => v.value == result?.gender)?.label || '____________'}
                                </span>
                            </div>
                            <div className="p-t">
                                <span className="address-info">
                                    Address: <span className="bold">{result?.addressEN || '____________'}</span>
                                </span>
                            </div>
                            <div className="">
                                {' '}
                                Local Level: <span className="bold">Katari Municipality</span>
                            </div>
                        </div>
                    </div>
                    <div className="user-image">
                        <img src={user_image} alt="user-image" />
                    </div>
                </div>
                <div className="p-t">
                    Disability nature:
                    <span className="p-t p-r">
                        <span className="bold p-l">
                            {disability_natureEN.find((v) => v.value == result?.disability_nature)?.label || '________________'}
                        </span>
                    </span>
                    <span className="p-t">
                        On the basis of Severity:
                        <span className="bold p-l">
                            {disabilitySeverityEN.find((v) => v.value == result?.disability_severity)?.label ||
                                '________________'}
                        </span>
                    </span>
                </div>
                <div className="flex">
                    <div className="left-content">
                        <div className="p-t">
                            Guardian Name: <span className="bold capitalize">{result?.guardians_nameEN || '________________'}</span>
                        </div>
                        <div className="p-t">Signature of ID card Holder: {'' || '________________'}</div>
                        <div className="p-t">
                            Approved by:<span className="bold"> {result?.approved_byEN || '_________'}</span>
                        </div>
                    </div>
                    <div className="left-content">
                        <div className="p-t">
                            Name: <span className="bold capitalize">{result?.full_nameEN || '____________'}</span>
                        </div>
                        <div className="p-t">Signature: {'' || '__________'}</div>
                        <div className="p-t">
                            Designation:<span className="bold"> {result?.positionEN || '_______'}</span>
                        </div>
                        <div className="p-t">Date: {'_____________'}</div>
                    </div>
                </div>
                <div className="lose-case">
                    <span>
                        If somebody founds this ID card, please deposit this in the nearby police station or municipality office
                    </span>
                </div>
            </div>
        </div>
    );
};

export function convertEnglishToNepali(englishNumber:any) {
    const digitMap:any = {
      '0': '०',
      '1': '१',
      '2': '२',
      '3': '३',
      '4': '४',
      '5': '५',
      '6': '६',
      '7': '७',
      '8': '८',
      '9': '९'
    };
  
    const nepaliNumber = englishNumber.replace(/-/g, '-').replace(/\d/g, (digit:any) => digitMap[digit]);
  
    return nepaliNumber;
  }
import {
    bloodGroupData,
    disability_nature,
    disability_natureEN,
    disabilitySeverity,
    disabilitySeverityEN,
    gender,
    genderEN,
    guardiansRelationEN,
    guardiansRelationNP,
    idCardTypes,
    idCardTypesEN,
} from '../../../../utils/constants/Constants';
import { IMAGE_URL } from '../../../../config/baseURL';
// @ts-ignore
import apanga from '../../../../assets/images/aa.png';
import { convertEnglishToNepali } from '../../../../utils/helpers/convertEnglishToNepali';

export const PrintDisableUserInfo = (printDisableData: any, governmentLogo: any, municipalityID: any) => {
    const user_image = `${IMAGE_URL}/storage/municipality/${municipalityID}/userinfo/image/${printDisableData.image}`;
    const qr_image = `${IMAGE_URL}/storage/municipality/${municipalityID}/userinfo/qr_code/${printDisableData.qr_code}`;
    const WinPrint: any = window.open();
    WinPrint.document.write(`<!DOCTYPE html>
      <html lang='en'>
      <head>
      <meta charset='UTF-8'>
      <meta http-equiv='X-UA-Compatible' content='IE=edge'>
      <meta name='viewport' content='width=device-width, initial-scale=1.0'>
      <style>
      @media print {
       * {
       -webkit-print-color-adjust: exact;
   }
      @page {
        size: 5in 3.5in;
        margin: 0;
        display: flex;
      }
      html, body {
      size: 5in 3.5in;

      display: flex;
      justify-content: center;
      align-items: center;
      /*transform: scale(0.990);*/
      box-sizing: border-box;
      /*margin-left:0.229in;*/
      margin-left: 0.49963in;
      margin-top: 0.1589in;
      margin-right: 0.0000393701in;
      margin-bottom: 0.089in;
      font-size: 12px;

}
      main{
  /*background: blue;*/
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 100vh;
  margin-top: 4mm;
    }
      .p-t{
      padding: 2px 0px;
      }
      /*.p-l{*/
      /*padding-left: 5px;*/
      /*}*/
      .contain{
      /*background: red;*/
      /*z-index: 100;      */
          width: 5in;
           height: 3.5in;
           padding: 0.02in 0.091in ;
          border: 1px dashed #000;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          /*padding: 10px;*/
          page-break-after: always;
           margin-right: 5mm;
          margin-bottom: 4mm;
      }
    
          .contain1{
      /*background: green;*/
      /*z-index: 100;      */
          width: 5in;
           height: 3.5in;
           padding: 0.02in 0.091in ;
          border: 1px dashed #000;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          /*padding: 10px;*/
          page-break-after: always;
          margin-top: 14mm;
          margin-right: 3mm;
          margin-bottom: 3mm;
          
      }
    
    .watermark {
            /*width: 100%;*/
            /*height: 100%;*/
            display: block;
            position: relative;
            
          }
          
            
            .watermark::after {
            content: "";
            
            background-image:url(${apanga});
            opacity: 0.2;
            top: 0%;
            left: 0.1%;
            bottom: 0;
           background-size:20%;          
           background-repeat:repeat;

           
            right: 0;
            position: absolute;
            z-index: -1;   
          }

      .header{
      margin: 0;
      padding: 0;
          display: flex;
          flex-direction: row;
          justify-content:space-between;
          align-items: center;
      }
      .header-logo{
          width: 80px ;
          margin-right: 10px;
          z-index: 100;
      }
      .qr-code{
      width: 70px;
      margin-right: 10px;
                z-index: 100;


      }
      .header-detail{
      padding-left: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      }
    
      .title{
          font-size: 13px;
          line-height: 15px;
          font-weight: bold;
          flex: 1;
          width: 70%;
          margin-right: 100px;
      }
      .bold{
      font-weight: bold;
      }

      .card-title{
      padding: 3px;
          width: 50%;
          margin:1px auto;
          display: flex;
          justify-content: center;
          background: #333132;
          color: white;
          font-size: 13px;
          line-height: 12px ;
                    z-index: 100;

      }

      .user-image{
      height:1.2in;
      width: 1.1in;
      border:1px solid black;
      margin-top: -10px;
      margin-right: 4px;
       z-index: 100;
      }
      img{
      height: inherit;
      width: inherit;
      object-fit: cover;
       z-index: 100;
      }
      .text-small{
      font-size: 9px;
      }
       .address-info{
      margin-right: 10px;
      }
      .card-main{
      margin-top: -0.2mm;
      display: flex;
      justify-content: space-between;
      }
      .flex{
      width: 100%;
      display: flex;
      /*align-items: center;*/
      }

      .left-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-basis: 100%;
        flex: 1;
      }
      .lose-case{
      margin-top: 3px;
      font-size: 8px;
      font-style: italic;
      text-align: center;
      }
    }
      </style>
      </head>

      <body>

      <main>
      <div class='contain'>
            <div class='watermark'>
            <div class='header'>
              <img class='header-logo'  src='${governmentLogo}' alt='government logo'>
              <div class='header-detail'>
                  <div class='title text-small '>नेपाल सरकार</div>
                   <div class='title'>कटारी नगरपालिका</div>
                   <div class='title'>नगर कार्यपालिकाको कार्यालय</div>
                   <div class='title text-small'>कटारी, उदयपुर</div>
                   <div class='title text-small'>कोशी प्रदेश, नेपाल</div>
               </div>
                <img class='qr-code'  src='${qr_image}' alt='user QR'>
           </div>
          <div class='card-title'>
              अपांगता परिचय-पत्र
          </div>        
   
          <div class='card-main'>
                <div class='flex'>
                    <div class='left-content'>
                          <div class='p-t'>परिचय पत्रको नम्बर:<span class='bold'>${
                            convertEnglishToNepali(String(printDisableData?.identity_no)) ?? ''
                          }</span> </div>
                          <div class='p-t'>नाम, थर: <span class='bold'>${printDisableData?.full_nameNP ?? ''}</span></div>
                         <div class='p-t'>नागरिकता नम्बर:<span class='bold'>${
                            convertEnglishToNepali(String(printDisableData?.citizenship_no)) || '        '
                         }</span> </div>
                         <div class='p-t'>रक्त समूह: <span class='bold'>${
                             bloodGroupData.find((v) => v.value == printDisableData?.blood_group)?.label || '__________'
                         }</span></div>
                          <div class=''> जिल्ला: <span class='bold'>उदयपुर</span></div>
                     </div>
                    <div class='left-content'>
                        <div class='p-t'>परिचय पत्रको प्रकार: <span class='bold'>${
                            idCardTypes.find((v) => v.value == printDisableData?.card_type_id)?.label ?? ''
                        }</span></div>
                        <div class='p-t'>जन्म मिति: <span class='bold'>${
                            convertEnglishToNepali(String(printDisableData?.date_of_birthNP)) || '__________'
                        }</span></div>
                        <div class='p-t'>लिङ्ग: <span class='bold'>${
                            gender.find((v) => v.value == printDisableData?.gender)?.label || '__________'
                        }</span></div>
                        <div class='p-t'><span class='address-info'>ठेगाना: <span class='bold'>कोशी प्रदेश</span></span>  </div>
                        <div class='p-t'> स्थानीय तह: <span class='bold'>कटारी नगरपालिका</span></div>
                    </div>
                </div>
                <div class='user-image'>
                <img src='${user_image}' alt='user-image'>
</div>
           </div>
           <div class='p-t'>अपांगताको प्रकृति: 
                <span class='p-t'><span class='bold p-l'>${
                    disability_nature.find((v) => v.value == printDisableData?.disability_nature)?.label || '__________'
                }</span></span>
                <span class='p-t p-l'>गम्भीरता: <span class='bold p-l'>${
                    disabilitySeverity.find((v) => v.value == printDisableData?.disability_severity)?.label || '__________'
                }</span></span>
           </div>
          <div class='flex'>
              <div class='left-content'>
                   <div class='p-t'>संरक्षकको नाम,थर: <span class='bold'>${
                       printDisableData?.guardians_nameNP || '__________'
                   }</span></div>
                   <div class='p-t'>परिचयपत्र वाहकको दस्तखत: ${'' || '__________'}</div>
                   <div class='p-t'>परिचयपत्र प्रमणित गर्ने:<span class='bold'> ${
                       printDisableData?.approved_byNP || '__________'
                   }</span></div>
               </div>
               <div class='left-content'>
                   <div class='p-t'>नाता: <span class='bold'>${
                       guardiansRelationNP.find((v) => v.value == printDisableData?.disability_severity)?.label || '__________'
                   }</span></div>
                   <div class='p-t'>हस्ताक्षर: ${'' || '__________'}</div>
                   <div class='p-t'>पद:<span class='bold'> ${printDisableData?.positionNP || '__________'}</span></div>
                   <div class='p-t'>मिति: ${'__________'}</div>
               </div>
          </div>
         <div class='lose-case'>
              <span >"यो परिचयपत्र कसैले पाएमा नजिकको प्रहरी कार्यालय वा स्थानीय निकायमा बुझाई दिनुहोला "</span>
         </div>
         </div>
      </div>
<!--      english-->
 <div class='contain1'>
    <div class='watermark'>
            <div class='header'>
              <img class='header-logo'  src='${governmentLogo}' alt='government logo'>
              <div class='header-detail'>
                  <div class='title text-small '>The Government of Nepal</div>
                   <div class='title'>Katari Municipality</div>
                   <div class='title'>Municipal Executive Office</div>
                   <div class='title text-small'>Katari, udayapur</div>
                   <div class='title text-small'>Koshi Province, Nepal</div>
               </div>
                 <img class='qr-code'  src='${qr_image}' alt='user QR'>
           </div>
          <div class='card-title'>
             Disability Identity Card
          </div>             
          <div class='card-main'>
                <div class='flex'>
                    <div class='left-content'>
                          <div class='p-t'>ID Card No.:<span class='bold'>${printDisableData?.identity_no ?? ''}</span> </div>
                          <div class='p-t'>Full Name: <span class='bold'>${printDisableData?.full_nameEN ?? ''}</span></div>
                         <div class='p-t'>Citizenship No:<span class='bold'>${
                             printDisableData?.citizenship_no || '__________'
                         }</span> </div>
                         <div class='p-t'>Blood Group: <span class='bold'>${
                             bloodGroupData.find((v) => v.value == printDisableData?.blood_group)?.label || '________________'
                         }</span></div>
                          <div class=''> Distric: <span class='bold'>Udayapur</span></div>
                     </div>
                    <div class='left-content'>
                        <div class='p-t'>ID Card Type: <span class='bold'>${
                            idCardTypesEN.find((v) => v.value == printDisableData?.card_type_id)?.label ?? ''
                        }</span></div>
                        <div class='p-t'>Date of Birth: <span class='bold'>${
                            printDisableData?.date_of_birthEN || '__________'
                        }</span></div>
                        <div class='p-t'>Sex: <span class='bold'>${
                            genderEN.find((v) => v.value == printDisableData?.gender)?.label || '__________'
                        }</span></div>
                        <div class='p-t'><span class='address-info'>Address: <span class='bold'>Koshi Province</span></span>  </div>
                        <div class=''> Local Level: <span class='bold'>Katari Municipality</span></div>
                    </div>
                </div>
                <div class='user-image'>
                <img src='${user_image}' alt='user-image'>
</div>
           </div>
           <div class='p-t'>Disability nature: 
               <span class='bold p-l'>${
                   disability_natureEN.find((v) => v.value == printDisableData?.disability_nature)?.label || '__________'
               }</span>
                <span class=''>On the basis of Severity<span class='bold p-l'>${
                    disabilitySeverityEN.find((v) => v.value == printDisableData?.disability_severity)?.label || '__________'
                }</span></span>
           </div>
          <div class='flex'>
              <div class='left-content'>
                   <div class='p-t'>Guardian Name: <span class='bold'>${
                       printDisableData?.guardians_nameEN || '__________'
                   }</span></div>
                   <div class='p-t'>Signature of ID card Holder: ${'' || '__________'}</div>
                   <div class='p-t'>Approved by:<span class='bold'> ${
                       printDisableData?.approved_byEN || '__________'
                   }</span></div>
               </div>
               <div class='left-content'>
                   <div class='p-t'>Guardians Relation: <span class='bold'>${
                       guardiansRelationEN.find((v) => v.value == printDisableData?.disability_severity)?.label || '__________'
                   }</span></div>
                   <div class='p-t'>Signature: ${'' || '__________'}</div>
                   <div class='p-t'>Designation:<span class='bold'> ${printDisableData?.positionEN || '__________'}</span></div>
                   <div class='p-t'>Date: ${'__________'}</div>
               </div>
          </div>
         <div class='lose-case'>
              <span >"If somebody founds this ID card, please deposit this in the nearby police station or municipality office"</span>
         </div>
         </div>
      </div>
</main>
</body>
</html>`);
    setTimeout(function () {
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    }, 250);
};
